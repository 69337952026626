@font-face {
    font-family: "summernote";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url("./font/summernote.eot?#iefix") format("embedded-opentype"), url("./font/summernote.woff2") format("woff2"), url("./font/summernote.woff") format("woff"), url("./font/summernote.ttf") format("truetype");
}
[class^=note-icon]:before,
[class*=" note-icon"]:before {
    display: inline-block;
    font-family: "summernote";
    font-style: normal;
    font-size: inherit;
    text-decoration: inherit;
    text-rendering: auto;
    text-transform: none;
    vertical-align: middle;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    speak: none;
}

.note-icon-fw {
    text-align: center;
    width: 1.25em;
}

.note-icon-border {
    border: solid 0.08em #eee;
    border-radius: 0.1em;
    padding: 0.2em 0.25em 0.15em;
}

.note-icon-pull-left {
    float: left;
}

.note-icon-pull-right {
    float: right;
}

.note-icon.note-icon-pull-left {
    margin-right: 0.3em;
}
.note-icon.note-icon-pull-right {
    margin-left: 0.3em;
}

.note-icon-align::before {
    content: "\ea01";
}

.note-icon-align-center::before {
    content: "\ea02";
}

.note-icon-align-indent::before {
    content: "\ea03";
}

.note-icon-align-justify::before {
    content: "\ea04";
}

.note-icon-align-left::before {
    content: "\ea05";
}

.note-icon-align-outdent::before {
    content: "\ea06";
}

.note-icon-align-right::before {
    content: "\ea07";
}

.note-icon-arrow-circle-down::before {
    content: "\ea08";
}

.note-icon-arrow-circle-left::before {
    content: "\ea09";
}

.note-icon-arrow-circle-right::before {
    content: "\ea0a";
}

.note-icon-arrow-circle-up::before {
    content: "\ea0b";
}

.note-icon-arrows-alt::before {
    content: "\ea0c";
}

.note-icon-arrows-h::before {
    content: "\ea0d";
}

.note-icon-arrows-v::before {
    content: "\ea0e";
}

.note-icon-bold::before {
    content: "\ea0f";
}

.note-icon-caret::before {
    content: "\ea10";
}

.note-icon-chain-broken::before {
    content: "\ea11";
}

.note-icon-circle::before {
    content: "\ea12";
}

.note-icon-close::before {
    content: "\ea13";
}

.note-icon-code::before {
    content: "\ea14";
}

.note-icon-col-after::before {
    content: "\ea15";
}

.note-icon-col-before::before {
    content: "\ea16";
}

.note-icon-col-remove::before {
    content: "\ea17";
}

.note-icon-eraser::before {
    content: "\ea18";
}

.note-icon-float-left::before {
    content: "\ea19";
}

.note-icon-float-none::before {
    content: "\ea1a";
}

.note-icon-float-right::before {
    content: "\ea1b";
}

.note-icon-font::before {
    content: "\ea1c";
}

.note-icon-frame::before {
    content: "\ea1d";
}

.note-icon-italic::before {
    content: "\ea1e";
}

.note-icon-link::before {
    content: "\ea1f";
}

.note-icon-magic::before {
    content: "\ea20";
}

.note-icon-menu-check::before {
    content: "\ea21";
}

.note-icon-minus::before {
    content: "\ea22";
}

.note-icon-orderedlist::before {
    content: "\ea23";
}

.note-icon-pencil::before {
    content: "\ea24";
}

.note-icon-picture::before {
    content: "\ea25";
}

.note-icon-question::before {
    content: "\ea26";
}

.note-icon-redo::before {
    content: "\ea27";
}

.note-icon-rollback::before {
    content: "\ea28";
}

.note-icon-row-above::before {
    content: "\ea29";
}

.note-icon-row-below::before {
    content: "\ea2a";
}

.note-icon-row-remove::before {
    content: "\ea2b";
}

.note-icon-special-character::before {
    content: "\ea2c";
}

.note-icon-square::before {
    content: "\ea2d";
}

.note-icon-strikethrough::before {
    content: "\ea2e";
}

.note-icon-subscript::before {
    content: "\ea2f";
}

.note-icon-summernote::before {
    content: "\ea30";
}

.note-icon-superscript::before {
    content: "\ea31";
}

.note-icon-table::before {
    content: "\ea32";
}

.note-icon-text-height::before {
    content: "\ea33";
}

.note-icon-trash::before {
    content: "\ea34";
}

.note-icon-underline::before {
    content: "\ea35";
}

.note-icon-undo::before {
    content: "\ea36";
}

.note-icon-unorderedlist::before {
    content: "\ea37";
}

.note-icon-video::before {
    content: "\ea38";
}

/* Theme Variables
 ------------------------------------------ */
/* Layout
 ------------------------------------------ */
.note-editor {
    position: relative;
}
.note-editor .note-dropzone {
    position: absolute;
    display: none;
    z-index: 100;
    color: lightskyblue;
    background-color: #fff;
    opacity: 0.95;
}
.note-editor .note-dropzone .note-dropzone-message {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
}
.note-editor .note-dropzone.hover {
    color: #098ddf;
}
.note-editor.dragover .note-dropzone {
    display: table;
}
.note-editor .note-editing-area {
    position: relative;
}
.note-editor .note-editing-area .note-editable {
    outline: none;
}
.note-editor .note-editing-area .note-editable sup {
    vertical-align: super;
}
.note-editor .note-editing-area .note-editable sub {
    vertical-align: sub;
}
.note-editor .note-editing-area .note-editable img.note-float-left {
    margin-right: 10px;
}
.note-editor .note-editing-area .note-editable img.note-float-right {
    margin-left: 10px;
}

/* Frame mode layout
 ------------------------------------------ */
.note-editor.note-frame,
.note-editor.note-airframe {
    border: 1px solid #00000032;
}
.note-editor.note-frame.codeview .note-editing-area .note-editable,
.note-editor.note-airframe.codeview .note-editing-area .note-editable {
    display: none;
}
.note-editor.note-frame.codeview .note-editing-area .note-codable,
.note-editor.note-airframe.codeview .note-editing-area .note-codable {
    display: block;
}
.note-editor.note-frame .note-editing-area,
.note-editor.note-airframe .note-editing-area {
    overflow: hidden;
}
.note-editor.note-frame .note-editing-area .note-editable,
.note-editor.note-airframe .note-editing-area .note-editable {
    padding: 10px;
    overflow: auto;
    word-wrap: break-word;
}
.note-editor.note-frame .note-editing-area .note-editable[contenteditable=false],
.note-editor.note-airframe .note-editing-area .note-editable[contenteditable=false] {
    background-color: #8080801d;
}
.note-editor.note-frame .note-editing-area .note-codable,
.note-editor.note-airframe .note-editing-area .note-codable {
    display: none;
    width: 100%;
    padding: 10px;
    border: none;
    box-shadow: none;
    font-family: Menlo, Monaco, monospace, sans-serif;
    font-size: 14px;
    color: #ccc;
    background-color: #222;
    resize: none;
    outline: none;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin-bottom: 0;
}
.note-editor.note-frame.fullscreen,
.note-editor.note-airframe.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    z-index: 1050;
}
.note-editor.note-frame.fullscreen .note-resizebar,
.note-editor.note-airframe.fullscreen .note-resizebar {
    display: none;
}
.note-editor.note-frame .note-status-output,
.note-editor.note-airframe .note-status-output {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 1.42857143;
    height: 20px;
    margin-bottom: 0;
    color: #000;
    border: 0;
    border-top: 1px solid #e2e2e2;
}
.note-editor.note-frame .note-status-output:empty,
.note-editor.note-airframe .note-status-output:empty {
    height: 0;
    border-top: 0 solid transparent;
}
.note-editor.note-frame .note-status-output .pull-right,
.note-editor.note-airframe .note-status-output .pull-right {
    float: right !important;
}
.note-editor.note-frame .note-status-output .text-muted,
.note-editor.note-airframe .note-status-output .text-muted {
    color: #777;
}
.note-editor.note-frame .note-status-output .text-primary,
.note-editor.note-airframe .note-status-output .text-primary {
    color: #286090;
}
.note-editor.note-frame .note-status-output .text-success,
.note-editor.note-airframe .note-status-output .text-success {
    color: #3c763d;
}
.note-editor.note-frame .note-status-output .text-info,
.note-editor.note-airframe .note-status-output .text-info {
    color: #31708f;
}
.note-editor.note-frame .note-status-output .text-warning,
.note-editor.note-airframe .note-status-output .text-warning {
    color: #8a6d3b;
}
.note-editor.note-frame .note-status-output .text-danger,
.note-editor.note-airframe .note-status-output .text-danger {
    color: #a94442;
}
.note-editor.note-frame .note-status-output .alert,
.note-editor.note-airframe .note-status-output .alert {
    margin: -7px 0 0 0;
    padding: 7px 10px 2px 10px;
    border-radius: 0;
    color: #000;
    background-color: #f5f5f5;
}
.note-editor.note-frame .note-status-output .alert .note-icon,
.note-editor.note-airframe .note-status-output .alert .note-icon {
    margin-right: 5px;
}
.note-editor.note-frame .note-status-output .alert-success,
.note-editor.note-airframe .note-status-output .alert-success {
    color: #3c763d !important;
    background-color: #dff0d8 !important;
}
.note-editor.note-frame .note-status-output .alert-info,
.note-editor.note-airframe .note-status-output .alert-info {
    color: #31708f !important;
    background-color: #d9edf7 !important;
}
.note-editor.note-frame .note-status-output .alert-warning,
.note-editor.note-airframe .note-status-output .alert-warning {
    color: #8a6d3b !important;
    background-color: #fcf8e3 !important;
}
.note-editor.note-frame .note-status-output .alert-danger,
.note-editor.note-airframe .note-status-output .alert-danger {
    color: #a94442 !important;
    background-color: #f2dede !important;
}
.note-editor.note-frame .note-statusbar,
.note-editor.note-airframe .note-statusbar {
    background-color: #8080801d;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid #00000032;
}
.note-editor.note-frame .note-statusbar .note-resizebar,
.note-editor.note-airframe .note-statusbar .note-resizebar {
    padding-top: 1px;
    height: 9px;
    width: 100%;
    cursor: ns-resize;
}
.note-editor.note-frame .note-statusbar .note-resizebar .note-icon-bar,
.note-editor.note-airframe .note-statusbar .note-resizebar .note-icon-bar {
    width: 20px;
    margin: 1px auto;
    border-top: 1px solid #00000032;
}
.note-editor.note-frame .note-statusbar.locked .note-resizebar,
.note-editor.note-airframe .note-statusbar.locked .note-resizebar {
    cursor: default;
}
.note-editor.note-frame .note-statusbar.locked .note-resizebar .note-icon-bar,
.note-editor.note-airframe .note-statusbar.locked .note-resizebar .note-icon-bar {
    display: none;
}
.note-editor.note-frame .note-placeholder,
.note-editor.note-airframe .note-placeholder {
    padding: 10px;
}

.note-editor.note-airframe {
    border: 0;
}
.note-editor.note-airframe .note-editing-area .note-editable {
    padding: 0;
}

/* Popover
 ------------------------------------------ */
.note-popover.popover {
    display: none;
    max-width: none;
}
.note-popover.popover .popover-content a {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
}
.note-popover.popover .arrow {
    left: 20px !important;
}

/* Popover and Toolbar (Button container)
 ------------------------------------------ */
.note-toolbar {
    position: relative;
}

.note-popover .popover-content, .note-editor .note-toolbar {
    margin: 0;
    padding: 0 0 5px 5px;
}
.note-popover .popover-content > .note-btn-group, .note-editor .note-toolbar > .note-btn-group {
    margin-top: 5px;
    margin-left: 0;
    margin-right: 5px;
}
.note-popover .popover-content .note-btn-group .note-table, .note-editor .note-toolbar .note-btn-group .note-table {
    min-width: 0;
    padding: 5px;
}
.note-popover .popover-content .note-btn-group .note-table .note-dimension-picker, .note-editor .note-toolbar .note-btn-group .note-table .note-dimension-picker {
    font-size: 18px;
}
.note-popover .popover-content .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher, .note-editor .note-toolbar .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher {
    position: absolute !important;
    z-index: 3;
    width: 10em;
    height: 10em;
    cursor: pointer;
}
.note-popover .popover-content .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted, .note-editor .note-toolbar .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted {
    position: relative !important;
    z-index: 1;
    width: 5em;
    height: 5em;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC") repeat;
}
.note-popover .popover-content .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted, .note-editor .note-toolbar .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted {
    position: absolute !important;
    z-index: 2;
    width: 1em;
    height: 1em;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC") repeat;
}
.note-popover .popover-content .note-style .dropdown-style blockquote, .note-popover .popover-content .note-style .dropdown-style pre, .note-editor .note-toolbar .note-style .dropdown-style blockquote, .note-editor .note-toolbar .note-style .dropdown-style pre {
    margin: 0;
    padding: 5px 10px;
}
.note-popover .popover-content .note-style .dropdown-style h1, .note-popover .popover-content .note-style .dropdown-style h2, .note-popover .popover-content .note-style .dropdown-style h3, .note-popover .popover-content .note-style .dropdown-style h4, .note-popover .popover-content .note-style .dropdown-style h5, .note-popover .popover-content .note-style .dropdown-style h6, .note-popover .popover-content .note-style .dropdown-style p, .note-editor .note-toolbar .note-style .dropdown-style h1, .note-editor .note-toolbar .note-style .dropdown-style h2, .note-editor .note-toolbar .note-style .dropdown-style h3, .note-editor .note-toolbar .note-style .dropdown-style h4, .note-editor .note-toolbar .note-style .dropdown-style h5, .note-editor .note-toolbar .note-style .dropdown-style h6, .note-editor .note-toolbar .note-style .dropdown-style p {
    margin: 0;
    padding: 0;
}
.note-popover .popover-content .note-color-all .note-dropdown-menu, .note-editor .note-toolbar .note-color-all .note-dropdown-menu {
    min-width: 337px;
}
.note-popover .popover-content .note-color .dropdown-toggle, .note-editor .note-toolbar .note-color .dropdown-toggle {
    width: 20px;
    padding-left: 5px;
}
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette, .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette {
    display: inline-block;
    margin: 0;
    width: 160px;
}
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette:first-child, .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette:first-child {
    margin: 0 5px;
}
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-palette-title, .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-palette-title {
    font-size: 12px;
    margin: 2px 7px;
    text-align: center;
    border-bottom: 1px solid #eee;
}
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-reset,
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-select, .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-reset,
.note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-select {
    font-size: 11px;
    margin: 3px;
    padding: 0 3px;
    cursor: pointer;
    width: 100%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-reset:hover,
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-select:hover, .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-reset:hover,
.note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-select:hover {
    background: #eee;
}
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-row, .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-row {
    height: 20px;
}
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-select-btn, .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-select-btn {
    display: none;
}
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-holder-custom .note-color-btn, .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-holder-custom .note-color-btn {
    border: 1px solid #eee;
}
.note-popover .popover-content .note-para .note-dropdown-menu, .note-editor .note-toolbar .note-para .note-dropdown-menu {
    min-width: 228px;
    padding: 5px;
}
.note-popover .popover-content .note-para .note-dropdown-menu > div + div, .note-editor .note-toolbar .note-para .note-dropdown-menu > div + div {
    margin-left: 5px;
}
.note-popover .popover-content .note-dropdown-menu, .note-editor .note-toolbar .note-dropdown-menu {
    min-width: 160px;
}
.note-popover .popover-content .note-dropdown-menu.right, .note-editor .note-toolbar .note-dropdown-menu.right {
    right: 0;
    left: auto;
}
.note-popover .popover-content .note-dropdown-menu.right::before, .note-editor .note-toolbar .note-dropdown-menu.right::before {
    right: 9px;
    left: auto !important;
}
.note-popover .popover-content .note-dropdown-menu.right::after, .note-editor .note-toolbar .note-dropdown-menu.right::after {
    right: 10px;
    left: auto !important;
}
.note-popover .popover-content .note-dropdown-menu.note-check a i, .note-editor .note-toolbar .note-dropdown-menu.note-check a i {
    color: deepskyblue;
    visibility: hidden;
}
.note-popover .popover-content .note-dropdown-menu.note-check a.checked i, .note-editor .note-toolbar .note-dropdown-menu.note-check a.checked i {
    visibility: visible;
}
.note-popover .popover-content .note-fontsize-10, .note-editor .note-toolbar .note-fontsize-10 {
    font-size: 10px;
}
.note-popover .popover-content .note-color-palette, .note-editor .note-toolbar .note-color-palette {
    line-height: 1;
}
.note-popover .popover-content .note-color-palette div .note-color-btn, .note-editor .note-toolbar .note-color-palette div .note-color-btn {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
}
.note-popover .popover-content .note-color-palette div .note-color-btn:hover, .note-editor .note-toolbar .note-color-palette div .note-color-btn:hover {
    transform: scale(1.2);
    transition: all 0.2s;
}

/* Dialog
 ------------------------------------------ */
.note-modal .modal-dialog {
    outline: 0;
    border-radius: 5px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}
.note-modal .form-group {
    margin-left: 0;
    margin-right: 0;
}
.note-modal .note-modal-form {
    margin: 0;
}
.note-modal .note-image-dialog .note-dropzone {
    min-height: 100px;
    font-size: 30px;
    line-height: 4;
    color: lightgray;
    text-align: center;
    border: 4px dashed lightgray;
    margin-bottom: 10px;
}
@-moz-document url-prefix() {
    .note-modal .note-image-input {
        height: auto;
    }
}

/* Placeholder
 ------------------------------------------ */
.note-placeholder {
    position: absolute;
    display: none;
    color: gray;
}

/* Handle
 ------------------------------------------ */
.note-handle .note-control-selection {
    position: absolute;
    display: none;
    border: 1px solid #000;
}
.note-handle .note-control-selection > div {
    position: absolute;
}
.note-handle .note-control-selection .note-control-selection-bg {
    width: 100%;
    height: 100%;
    background-color: #000;
    -webkit-opacity: 0.3;
    -khtml-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=30);
    filter: alpha(opacity=30);
}
.note-handle .note-control-selection .note-control-handle, .note-handle .note-control-selection .note-control-sizing, .note-handle .note-control-selection .note-control-holder {
    width: 7px;
    height: 7px;
    border: 1px solid #000;
}
.note-handle .note-control-selection .note-control-sizing {
    background-color: #000;
}
.note-handle .note-control-selection .note-control-nw {
    top: -5px;
    left: -5px;
    border-right: none;
    border-bottom: none;
}
.note-handle .note-control-selection .note-control-ne {
    top: -5px;
    right: -5px;
    border-bottom: none;
    border-left: none;
}
.note-handle .note-control-selection .note-control-sw {
    bottom: -5px;
    left: -5px;
    border-top: none;
    border-right: none;
}
.note-handle .note-control-selection .note-control-se {
    right: -5px;
    bottom: -5px;
    cursor: se-resize;
}
.note-handle .note-control-selection .note-control-se.note-control-holder {
    cursor: default;
    border-top: none;
    border-left: none;
}
.note-handle .note-control-selection .note-control-selection-info {
    right: 0;
    bottom: 0;
    padding: 5px;
    margin: 5px;
    color: #fff;
    background-color: #000;
    font-size: 12px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-opacity: 0.7;
    -khtml-opacity: 0.7;
    -moz-opacity: 0.7;
    opacity: 0.7;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=70);
    filter: alpha(opacity=70);
}

.note-hint-popover {
    min-width: 100px;
    padding: 2px;
}
.note-hint-popover .popover-content {
    padding: 3px;
    max-height: 150px;
    overflow: auto;
}
.note-hint-popover .popover-content .note-hint-group .note-hint-item {
    display: block !important;
    padding: 3px;
}
.note-hint-popover .popover-content .note-hint-group .note-hint-item.active, .note-hint-popover .popover-content .note-hint-group .note-hint-item:hover {
    display: block;
    clear: both;
    font-weight: 400;
    line-height: 1.4;
    color: white;
    white-space: nowrap;
    text-decoration: none;
    background-color: #428bca;
    outline: 0;
    cursor: pointer;
}

/* Handle
 ------------------------------------------ */
html .note-fullscreen-body, body .note-fullscreen-body {
    overflow: hidden !important;
}