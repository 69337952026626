// Variables

$sni-css-prefix: note-icon !default;

// Path

@font-face {
  font-family: "summernote";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("./font/summernote.eot?#iefix") format("embedded-opentype"), url("./font/summernote.woff2") format("woff2"), url("./font/summernote.woff") format("woff"), url("./font/summernote.ttf") format("truetype");}

// Core

[class^="#{$sni-css-prefix}"]:before,
[class*=" #{$sni-css-prefix}"]:before {
  display: inline-block;
  font-family: "summernote";
  font-style: normal;
  font-size: inherit;
  text-decoration: inherit;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  speak: none;
}

// Extras

.#{$sni-css-prefix}-fw {
  text-align: center;
  width: 1.25em;
}

.#{$sni-css-prefix}-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.#{$sni-css-prefix}-pull-left {
  float: left;
}

.#{$sni-css-prefix}-pull-right {
  float: right;
}

.#{$sni-css-prefix} {
  &.#{$sni-css-prefix}-pull-left {
    margin-right: 0.3em;
  }
  &.#{$sni-css-prefix}-pull-right {
    margin-left: 0.3em;
  }
}

// Functions

@function char($character-code) {
  @if function-exists("selector-append") {
    @return unquote("\"\\#{$character-code}\"");
  }

  @if "\\#{'x'}" == "\\x" {
    @return str-slice("\x", 1, 1) + $character-code;
  }
  @else {
    @return #{"\"\\"}#{$character-code + "\""};
  }
}

// Icons


.note-icon-align::before {
  content: "\ea01";
}

.note-icon-align-center::before {
  content: "\ea02";
}

.note-icon-align-indent::before {
  content: "\ea03";
}

.note-icon-align-justify::before {
  content: "\ea04";
}

.note-icon-align-left::before {
  content: "\ea05";
}

.note-icon-align-outdent::before {
  content: "\ea06";
}

.note-icon-align-right::before {
  content: "\ea07";
}

.note-icon-arrow-circle-down::before {
  content: "\ea08";
}

.note-icon-arrow-circle-left::before {
  content: "\ea09";
}

.note-icon-arrow-circle-right::before {
  content: "\ea0a";
}

.note-icon-arrow-circle-up::before {
  content: "\ea0b";
}

.note-icon-arrows-alt::before {
  content: "\ea0c";
}

.note-icon-arrows-h::before {
  content: "\ea0d";
}

.note-icon-arrows-v::before {
  content: "\ea0e";
}

.note-icon-bold::before {
  content: "\ea0f";
}

.note-icon-caret::before {
  content: "\ea10";
}

.note-icon-chain-broken::before {
  content: "\ea11";
}

.note-icon-circle::before {
  content: "\ea12";
}

.note-icon-close::before {
  content: "\ea13";
}

.note-icon-code::before {
  content: "\ea14";
}

.note-icon-col-after::before {
  content: "\ea15";
}

.note-icon-col-before::before {
  content: "\ea16";
}

.note-icon-col-remove::before {
  content: "\ea17";
}

.note-icon-eraser::before {
  content: "\ea18";
}

.note-icon-float-left::before {
  content: "\ea19";
}

.note-icon-float-none::before {
  content: "\ea1a";
}

.note-icon-float-right::before {
  content: "\ea1b";
}

.note-icon-font::before {
  content: "\ea1c";
}

.note-icon-frame::before {
  content: "\ea1d";
}

.note-icon-italic::before {
  content: "\ea1e";
}

.note-icon-link::before {
  content: "\ea1f";
}

.note-icon-magic::before {
  content: "\ea20";
}

.note-icon-menu-check::before {
  content: "\ea21";
}

.note-icon-minus::before {
  content: "\ea22";
}

.note-icon-orderedlist::before {
  content: "\ea23";
}

.note-icon-pencil::before {
  content: "\ea24";
}

.note-icon-picture::before {
  content: "\ea25";
}

.note-icon-question::before {
  content: "\ea26";
}

.note-icon-redo::before {
  content: "\ea27";
}

.note-icon-rollback::before {
  content: "\ea28";
}

.note-icon-row-above::before {
  content: "\ea29";
}

.note-icon-row-below::before {
  content: "\ea2a";
}

.note-icon-row-remove::before {
  content: "\ea2b";
}

.note-icon-special-character::before {
  content: "\ea2c";
}

.note-icon-square::before {
  content: "\ea2d";
}

.note-icon-strikethrough::before {
  content: "\ea2e";
}

.note-icon-subscript::before {
  content: "\ea2f";
}

.note-icon-summernote::before {
  content: "\ea30";
}

.note-icon-superscript::before {
  content: "\ea31";
}

.note-icon-table::before {
  content: "\ea32";
}

.note-icon-text-height::before {
  content: "\ea33";
}

.note-icon-trash::before {
  content: "\ea34";
}

.note-icon-underline::before {
  content: "\ea35";
}

.note-icon-undo::before {
  content: "\ea36";
}

.note-icon-unorderedlist::before {
  content: "\ea37";
}

.note-icon-video::before {
  content: "\ea38";
}

